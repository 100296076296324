export const countryData = {
  QA: {
    cities: {
      en: [
        { label: '', value: '' },
        { label: 'Doha', value: 'DOHA' },
        { label: 'Al Wakrah', value: 'AL_WAKRAH' },
        { label: 'Al Khor', value: 'AL_KHOR' },
        { label: 'Al Rayyan', value: 'AL_RAYYAN' },
        { label: 'Umm Salal', value: 'UMM_SALAL' },
        { label: 'Madinat ash Shamal', value: 'MADINAT_ASH_SHAMAL' },
        { label: 'Al Daayen', value: 'AL_DAAYEN' },
        { label: 'Al Shahaniya', value: 'AL_SHAHANIYA' },
      ],
      ar: [
        { label: '', value: '' },
        { label: 'الدوحة', value: 'DOHA' },
        { label: 'الوكرة', value: 'AL_WAKRAH' },
        { label: 'الخور', value: 'AL_KHOR' },
        { label: 'الريان', value: 'AL_RAYYAN' },
        { label: 'أم صلال', value: 'UMM_SALAL' },
        { label: 'مدينة الشمال', value: 'MADINAT_ASH_SHAMAL' },
        { label: 'الظعاين', value: 'AL_DAAYEN' },
        { label: 'الشحانية', value: 'AL_SHAHANIYA' },
      ],
    },
    countryCode: 'QA',
    currency: { ar: 'ريال قطري', en: 'QAR' },
    vatRate: 5,
  },
  OM: {
    cities: {
      en: [
        { label: '', value: '' },
        { label: 'Muscat', value: 'MUSCAT' },
        { label: 'Salalah', value: 'SALALAH' },
        { label: 'Sohar', value: 'SOHAR' },
        { label: 'Nizwa', value: 'NIZWA' },
        { label: 'Sur', value: 'SUR' },
        { label: 'Barka', value: 'BARKA' },
        { label: 'Rustaq', value: 'RUSTAQ' },
        { label: 'Ibri', value: 'IBRI' },
        { label: 'Ibra', value: 'IBRA' },
        { label: 'Duqm', value: 'DUQM' },
        { label: 'Khasab', value: 'KHASAB' },
        { label: 'Bahla', value: 'BAHLA' },
        { label: 'Shinas', value: 'SHINAS' },
      ],
      ar: [
        { label: '', value: '' },
        { label: 'مسقط', value: 'MUSCAT' },
        { label: 'صلالة', value: 'SALALAH' },
        { label: 'صحار', value: 'SOHAR' },
        { label: 'نزوى', value: 'NIZWA' },
        { label: 'صور', value: 'SUR' },
        { label: 'بركاء', value: 'BARKA' },
        { label: 'الرستاق', value: 'RUSTAQ' },
        { label: 'عبري', value: 'IBRI' },
        { label: 'إبراء', value: 'IBRA' },
        { label: 'الدقم', value: 'DUQM' },
        { label: 'خصب', value: 'KHASAB' },
        { label: 'بهلاء', value: 'BAHLA' },
        { label: 'شناص', value: 'SHINAS' },
      ],
    },
    countryCode: 'OM',
    currency: { ar: 'ريال عماني', en: 'OMR' },
    vatRate: 5,
  },
  AE: {
    cities: {
      en: [
        { label: '', value: '' },
        { label: 'Abu Dhabi', value: 'ABU_DHABI' },
        { label: 'Dubai', value: 'DUBAI' },
        { label: 'Sharjah', value: 'SHARJAH' },
        { label: 'Ajman', value: 'AJMAN' },
        { label: 'Umm Al Quwain', value: 'UMM_AL_QUWAIN' },
        { label: 'Ras Al Khaimah', value: 'RAS_AL_KHAIMAH' },
        { label: 'Fujairah', value: 'FUJAIRAH' },
        { label: 'Al Ain', value: 'AL_AIN' },
        { label: 'Khor Fakkan', value: 'KHOR_FAKKAN' },
        { label: 'Dibba Al-Fujairah', value: 'DIBBA_AL_FUJAIRAH' },
      ],
      ar: [
        { label: '', value: '' },
        { label: 'أبو ظبي', value: 'ABU_DHABI' },
        { label: 'دبي', value: 'DUBAI' },
        { label: 'الشارقة', value: 'SHARJAH' },
        { label: 'عجمان', value: 'AJMAN' },
        { label: 'أم القيوين', value: 'UMM_AL_QUWAIN' },
        { label: 'رأس الخيمة', value: 'RAS_AL_KHAIMAH' },
        { label: 'الفجيرة', value: 'FUJAIRAH' },
        { label: 'العين', value: 'AL_AIN' },
        { label: 'خورفكان', value: 'KHOR_FAKKAN' },
        { label: 'دبا الفجيرة', value: 'DIBBA_AL_FUJAIRAH' },
      ],
    },
    currency: { ar: 'درهم إماراتي', en: 'AED' },
    countryCode: 'AE',
    vatRate: 5,
  },
  SA: {
    cities: {
      en: [
        { label: '', value: '' },
        { label: 'Riyadh', value: 'RIYADH' },
        { label: 'Jeddah', value: 'JEDDAH' },
        { label: 'Mecca', value: 'MECCA' },
        { label: 'Medina', value: 'MEDINA' },
        { label: 'Dammam', value: 'DAMMAM' },
        { label: 'Khobar', value: 'KHOBAR' },
        { label: 'Tabuk', value: 'TABUK' },
        { label: 'Buraydah', value: 'BURAYDAH' },
        { label: 'Khamis Mushait', value: 'KHAMIS_MUSHAIT' },
        { label: 'Hofuf', value: 'HOFUF' },
        { label: 'Hail', value: 'HAIL' },
        { label: 'Najran', value: 'NAJRAN' },
        { label: 'Abha', value: 'ABHA' },
        { label: 'Jubail', value: 'JUBAIL' },
        { label: 'Yanbu', value: 'YANBU' },
        { label: 'Al Qatif', value: 'AL_QATIF' },
        { label: 'Al Khafji', value: 'AL_KHAFJI' },
        { label: 'Al Bahah', value: 'AL_BAHAH' },
        { label: 'Arar', value: 'ARAR' },
        { label: 'Sakakah', value: 'SAKAKAH' },
        { label: 'Jizan', value: 'JIZAN' },
        { label: 'Qurayyat', value: 'QURAYYAT' },
        { label: 'Dhahran', value: 'DHAHRAN' },
        { label: 'Al Kharj', value: 'AL_KHARJ' },
        { label: 'Taif', value: 'TAIF' },
        { label: 'Al Mubarraz', value: 'AL_MUBARRAZ' },
        { label: 'Hafar Al-Batin', value: 'HAFAR_AL-BATIN' },
        { label: 'Al Jubayl', value: 'AL_JUBAYL' },
        { label: 'Al Qunfudhah', value: 'AL_QUNFUDHAH' },
        { label: 'Rabigh', value: 'RABIGH' },
        { label: 'Rafha', value: 'RAFHA' },
        { label: 'Ar Rass', value: 'AR_RASS' },
        { label: 'Ras Tanura', value: 'RAS_TANURA' },
        { label: 'Riyadh Al-Khabra', value: 'RIYADH_AL-KHABRA' },
        { label: 'Sabya', value: 'SABYA' },
        { label: 'Saihat', value: 'SAIHAT' },
        { label: 'Safwa', value: 'SAFWA' },
        { label: 'Sharurah', value: 'SHARURAH' },
        { label: 'Shaqra', value: 'SHAQRA' },
        { label: 'Taif', value: 'TAIF' },
        { label: 'Tabuk', value: 'TABUK' },
        { label: 'Tarout', value: 'TAROUT' },
        { label: 'Tayma', value: 'TAYMA' },
        { label: 'Thuwal', value: 'THUWAL' },
        { label: 'Turaif', value: 'TURAIF' },
        { label: 'Umluj', value: 'UMLUJ' },
        { label: 'Unaizah', value: 'UNAIZAH' },
        { label: 'Wadi Al-Dawasir', value: 'WADI_AL-DAWASIR' },
        { label: 'Al Wajh', value: 'AL_WAJH' },
        { label: 'Yanbu', value: 'YANBU' },
        { label: 'Zulfi', value: 'ZULFI' }
      ],
      ar: [
        { label: '', value: '' },
        { label: 'الرياض', value: 'RIYADH' },
        { label: 'جدة', value: 'JEDDAH' },
        { label: 'مكة', value: 'MECCA' },
        { label: 'المدينة المنورة', value: 'MEDINA' },
        { label: 'الدمام', value: 'DAMMAM' },
        { label: 'الخبر', value: 'KHOBAR' },
        { label: 'تبوك', value: 'TABUK' },
        { label: 'بريدة', value: 'BURAYDAH' },
        { label: 'خميس مشيط', value: 'KHAMIS_MUSHAIT' },
        { label: 'الهفوف', value: 'HOFUF' },
        { label: 'حائل', value: 'HAIL' },
        { label: 'نجران', value: 'NAJRAN' },
        { label: 'أبها', value: 'ABHA' },
        { label: 'الجبيل', value: 'JUBAIL' },
        { label: 'ينبع', value: 'YANBU' },
        { label: 'القطيف', value: 'AL_QATIF' },
        { label: 'الخفجي', value: 'AL_KHAFJI' },
        { label: 'الباحة', value: 'AL_BAHAH' },
        { label: 'عرعر', value: 'ARAR' },
        { label: 'سكاكا', value: 'SAKAKAH' },
        { label: 'جيزان', value: 'JIZAN' },
        { label: 'القريات', value: 'QURAYYAT' },
        { label: 'الظهران', value: 'DHAHRAN' },
        { label: 'الخرج', value: 'AL_KHARJ' },
        { label: 'الطائف', value: 'TAIF' },
        { label: 'المبرز', value: 'AL_MUBARRAZ' },
        { label: 'حفر الباطن', value: 'HAFAR_AL-BATIN' },
        { label: 'الجبيل', value: 'AL_JUBAYL' },
        { label: 'القنفذة', value: 'AL_QUNFUDHAH' },
        { label: 'رابغ', value: 'RABIGH' },
        { label: 'رفحاء', value: 'RAFHA' },
        { label: 'الرس', value: 'AR_RASS' },
        { label: 'رأس تنورة', value: 'RAS_TANURA' },
        { label: 'رياض الخبراء', value: 'RIYADH_AL-KHABRA' },
        { label: 'صبيا', value: 'SABYA' },
        { label: 'سيهات', value: 'SAIHAT' },
        { label: 'صفوى', value: 'SAFWA' },
        { label: 'شرورة', value: 'SHARURAH' },
        { label: 'شقراء', value: 'SHAQRA' },
        { label: 'ثول', value: 'THUWAL' },
        { label: 'تربة', value: 'TURAIF' },
        { label: 'أملج', value: 'UMLUJ' },
        { label: 'عنيزة', value: 'UNAIZAH' },
        { label: 'وادي الدواسر', value: 'WADI_AL-DAWASIR' },
        { label: 'الوجه', value: 'AL_WAJH' },
        { label: 'الزلفي', value: 'ZULFI' }
      ]
    },
    countryCode: 'SA',
    currency: { ar: 'ريال سعودي', en: 'SAR' },
    vatRate: 15
  },
  BH: {
    cities: {
      en: [
        { label: '', value: '' },
        { label: 'Manama', value: 'MANAMA' },
        { label: 'Riffa', value: 'RIFFA' },
        { label: 'Muharraq', value: 'MUHARRAQ' },
        { label: 'Hamad Town', value: 'HAMAD_TOWN' },
        { label: 'Isa Town', value: 'ISA_TOWN' },
        { label: 'Sitra', value: 'SITRA' },
        { label: 'Budaiya', value: 'BUDAIYA' },
        { label: 'Jidhafs', value: 'JIDHAFS' },
        { label: 'Al Hidd', value: 'AL_HIDD' },
        { label: 'Zallaq', value: 'ZALLAQ' },
        { label: 'Dar Kulaib', value: 'DAR_KULAIB' },
        { label: 'Tubli', value: 'TUBLI' },
        { label: 'Jannusan', value: 'JANNUHAN' },
        { label: 'Salmabad', value: 'SALMABAD' },
        { label: 'Al Jasra', value: 'AL_JASRA' },
        { label: 'Sanabis', value: 'SANABIS' },
        { label: 'Barbar', value: 'BARBAR' },
        { label: 'Bani Jamra', value: 'BANI_JAMRA' },
        { label: 'Dumistan', value: 'DUMISTAN' }
      ],
      ar: [
        { label: '', value: '' },
        { label: 'المنامة', value: 'MANAMA' },
        { label: 'الرفاع', value: 'RIFFA' },
        { label: 'المحرق', value: 'MUHARRAQ' },
        { label: 'مدينة حمد', value: 'HAMAD_TOWN' },
        { label: 'مدينة عيسى', value: 'ISA_TOWN' },
        { label: 'سترة', value: 'SITRA' },
        { label: 'البديع', value: 'BUDAIYA' },
        { label: 'جد حفص', value: 'JIDHAFS' },
        { label: 'الحد', value: 'AL_HIDD' },
        { label: 'الزلاق', value: 'ZALLAQ' },
        { label: 'دار كليب', value: 'DAR_KULAIB' },
        { label: 'توبلي', value: 'TUBLI' },
        { label: 'جنوسان', value: 'JANNUHAN' },
        { label: 'سلماباد', value: 'SALMABAD' },
        { label: 'الجسرة', value: 'AL_JASRA' },
        { label: 'سنابس', value: 'SANABIS' },
        { label: 'باربار', value: 'BARBAR' },
        { label: 'بني جمرة', value: 'BANI_JAMRA' },
        { label: 'دمستان', value: 'DUMISTAN' }
      ],
    },
    countryCode: 'BH',
    currency: { ar: 'دينار بحريني', en: 'BHD' },
    vatRate: 10
  }
};
