import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './routesConfig';
import { useKeycloak } from '../../hooks/useKeycloak';
import { EInvoicingPortal } from '../../pages/EInvoicingPortal';

const AppRoutes = ({ KEYCLOAK_REALM, baseRoute, TAX_COUNTRY }) => {
  const [routes, setRoutes] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const { roles } = useKeycloak();
  const renderRoutes = (appRoutes) => {
    return appRoutes.map(({ path, element, nestedRoutes }, index) =>
      element ? (
        <Route key={index} path={path} element={element}>
          {nestedRoutes && nestedRoutes.length > 0
            ? renderRoutes(nestedRoutes)
            : null}
        </Route>
      ) : (
        <Route key={index} path={path}>
          {nestedRoutes && nestedRoutes.length > 0
            ? renderRoutes(nestedRoutes)
            : null}
        </Route>
      ),
    );
  };

  useEffect(() => {
    if (roles && roles.length) {
      const routeConfig = {
        showSolutionRegistration: roles.indexOf('taxpayer') !== -1,
        showDashboard: roles.indexOf('taxpayer') !== -1,
        showTaxReturn: roles.indexOf('tax_return') !== -1,
        showInvoiceView: roles.indexOf('service_provider') !== -1,
        showServiceProvider: roles.indexOf('service_provider') !== -1,
        showSme: roles.indexOf('sme_portal') !== -1,
        smeTypes: ['standard', 'simplified'],
      };
      setRoutes(
        ROUTES({
          KEYCLOAK_REALM,
          baseRoute,
          TAX_COUNTRY,
          config: routeConfig,
        }),
      );
    }
    setIsInitialized(true);
  }, [roles, baseRoute]);

  return (
    <Routes>
      {isInitialized && renderRoutes(routes)}
      <Route
        path={baseRoute}
        element={<EInvoicingPortal KEYCLOAK_REALM={KEYCLOAK_REALM} />}
      />
      {/* <Route
        path="/*"
        element={
          window.location.pathname === baseRoute || !isInitialized ? null : (
            <Navigate to={baseRoute} replace={true} />
          )
        }
      /> */}
    </Routes>
  );
};

export default React.memo(AppRoutes);
