import React from 'react';
import styles from './Section.styles.scss';
import { Form, Formik } from 'formik';
import { FormErrorToast } from '../../../../../SME/Pages/CreateInvoice/components/shared/FormErrorToast';
import { FormikField } from '../../../../../../components/FormikField';
import { Button } from '../../../../../../components/shared/Button';
import { Icon } from '../../../../../../components/shared/Icon';
import cn from 'classnames';
import { LineItemsTable } from '../LineItemsTable';
import { TotalsTable } from '../TotalsTable';
import { useTranslation } from 'react-i18next';
import { useCustomContext } from '../../../../../../context/customContext';

const Section = ({
  inputs,
  getInputType,
  validationSchema,
  type,
  handleFormSubmit,
  initialValues,
  activeStep,
  t,
  onAddItemClick,
  getComponentProps,
  previousInvoices,
  resetInvoice,
  onCancelClick,
  taxCountry,
}) => {
  const { i18n } = useTranslation();
  const { countryData } = useCustomContext();

  return (
    <div className={cn('bg-white w-100', styles.container)}>
      <div className={styles.actionButtons}>
        {/* <span className={cn(styles.iconContainer, 'mx-2', styles.saveIcon)}>
          <Icon name={'saveForm'} />
          <h6 className="mx-1">Save</h6>
        </span> */}
        {activeStep.withReset && (
          <span
            onClick={resetInvoice}
            className={cn(styles.iconContainer, 'mx-2')}
          >
            <Icon name="resetForm" />
            <h6 className="mx-1">{t('createInvoice.reset')}</h6>
          </span>
        )}
      </div>
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema({ type, t })}
        onSubmit={(e) => handleFormSubmit(e)}
        initialValues={initialValues}
      >
        {({
          values,
          resetForm,
          setFieldValue,
          validateForm,
          setErrors,
          setTouched,
          setFieldTouched,
        }) => (
          <>
            <FormErrorToast />
            <Form>
              <div>
                <div className="row">
                  {inputs({
                    type,
                    values,
                    setFieldValue,
                    setFieldTouched,
                    previousInvoices,
                    initialValues,
                    taxCountry,
                    language: i18n.language,
                    cities: countryData?.cities?.[i18n.language],
                    countryData: countryData,
                  }).map(
                    (input, i) =>
                      !input.hide && (
                        <FormikField
                          name={input.name}
                          component={getInputType(input.type)}
                          componentProps={{
                            className: cn(
                              input.type !== 'textarea' ? styles.field : '',
                              input.className,
                            ),
                            ...getComponentProps(input),
                            // disabledDates: input.disabledDates,
                          }}
                          controlProps={{
                            labelProps: { className: styles.label },
                            className: input.className,
                          }}
                          label={
                            typeof input.label === 'string'
                              ? t(input.label)
                              : t(input.label?.value, {
                                  ...input.label?.placeholder,
                                })
                          }
                          required={input.required}
                          key={i}
                        />
                      ),
                  )}
                </div>
                {activeStep.value === 'lineItems' && (
                  <>
                    <div className={styles.addItemDiv}>
                      <Button
                        className={'col-4 mb-5'}
                        variant={'outlined'}
                        onClick={() =>
                          onAddItemClick({
                            values,
                            validateForm,
                            setErrors,
                            setTouched,
                            resetForm,
                          })
                        }
                        id="add_btn"
                      >
                        {t('add_to_list')}
                      </Button>
                    </div>
                    <div>
                      <LineItemsTable
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      {values?.items?.length > 0 && (
                        <TotalsTable items={values.items} />
                      )}
                    </div>
                  </>
                )}
                <div className={styles.buttonsContainer}>
                  <Button
                    className={'col-2 mx-2'}
                    variant={'outlined'}
                    type="button"
                    onClick={onCancelClick}
                    id={activeStep.value + '_cancel_btn'}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    id={activeStep.value + '_continue_btn'}
                    className={'col-2 mx-2'}
                    type="submit"
                  >
                    {t('continue')}
                  </Button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(Section);
