import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from './context/customContext';

import { useTranslation } from 'react-i18next';
import KeycloakProvider from './config/KeycloakProvider';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import {
  initiateServiceInstance,
  initiateSmeInstance,
  initiateCertificationInstance,
  initiatePrintInstance,
  initiateTaxReturnInstance,
} from './config/axios';
import * as constants from './constants/common';
import { changeTheme } from './helpers/changeTheme';

import AppRoutes from './config/AppRoutes/AppRoutes';
import { countryData } from './helpers/countryValues';

function App() {
  const { i18n } = useTranslation();
  const baseRoute = `/${i18n.language}`;

  const KEYCLOAK_CLIENT_ID =
    window._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || constants.KEYCLOAK_CLIENT_ID;
  const KEYCLOAK_URL =
    window._env_?.REACT_APP_KEYCLOAK_URL || constants.KEYCLOAK_URL;
  const KEYCLOAK_REALM =
    window._env_?.REACT_APP_KEYCLOAK_REALM || constants.KEYCLOAK_REALM;
  const API_URL = window._env_?.REACT_APP_API_URL || constants.API_URL;

  const SME_URL = window._env_?.REACT_APP_SME_URL || constants.SME_URL;

  const ONBOARDING_GRAVITEE_KEY =
    window._env_?.REACT_APP_ONBOARDING_GRAVITEE_KEY ||
    constants.ONBOARDING_GRAVITEE_KEY;

  const SME_GRAVITEE_KEY =
    window._env_?.REACT_APP_SME_GRAVITEE_KEY || constants.SME_GRAVITEE_KEY;

  const CERTIFICATION_GRAVITEE_KEY =
    window._env_?.REACT_APP_CERTIFICATION_GRAVITEE_KEY ||
    constants.CERTIFICATION_GRAVITEE_KEY;

  const PRINT_URL = window._env_?.REACT_APP_PRINT_URL || constants.PRINT_URL;

  const TAX_RETURN_URL =
    window._env_?.REACT_APP_TAX_RETURN_URL || constants.TAX_RETURN_URL;

  const TAX_COUNTRY =
    window._env_?.REACT_APP_TAX_COUNTRY || constants.TAX_COUNTRY;

  initiateServiceInstance({ API_URL, ONBOARDING_GRAVITEE_KEY });
  initiateSmeInstance({ SME_URL, SME_GRAVITEE_KEY });
  initiateCertificationInstance({ API_URL, CERTIFICATION_GRAVITEE_KEY });
  initiatePrintInstance({ PRINT_URL });
  initiateTaxReturnInstance({ TAX_RETURN_URL });

  changeTheme(constants.THEMES[KEYCLOAK_REALM] || constants.THEMES.eiv);

  useEffect(() => {
    document.title = KEYCLOAK_REALM;
  }, []);

  const country = window?._env_?.REACT_APP_COUNTRY || 'AQ';

  return (
    <BrowserRouter>
      <KeycloakProvider
        KEYCLOAK_URL={KEYCLOAK_URL}
        KEYCLOAK_REALM={KEYCLOAK_REALM}
        KEYCLOAK_CLIENT_ID={KEYCLOAK_CLIENT_ID}
      >
        <Provider>
          <AppRoutes
            KEYCLOAK_REALM={KEYCLOAK_REALM}
            TAX_COUNTRY={TAX_COUNTRY}
            baseRoute={baseRoute}
            countryData={countryData[country]}
          />
          <ToastContainer hideProgressBar newestOnTop={false} closeOnClick />
        </Provider>
      </KeycloakProvider>
    </BrowserRouter>
  );
}

export default React.memo(App);
